html,
body {
  margin: 0;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Arial, Helvetica, sans-serif;
  height: 100%;
}
.root {
  height: 100%;
}
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
